/* You can add global styles to this file, and also import other style files */

@import 'themes/themes';
@import 'ngx-toastr/toastr';

@media only screen and (min-width: 1200px) {
	.mat-dialog-actions .mat-button {
		width: 110px !important;
		font-size: 15px !important;
	}
}

@media only screen and (max-width: 1199px) {
	.mat-dialog-actions .mat-button {
		width: fit-content !important;
		font-size: 1.2vh !important;
	}
}

body {
	margin: 0;
}

#footer .copyright {
	padding-top: 5px !important;
}

#footer p {
	margin: 0 0 20px 0;
}

.social-links {
	margin: 0 0 15px 0 !important;
}

.splash-screen-window {
	width: 100%;
	height: 100%;
	z-index: 10;
	font-family: Tahoma !important;

	.splash-screen {
		scale: 0.6;
	}

	.welcome-text, .to-text {
		width: 100%;
		font-size: 120px;
		font-weight: 500;
		letter-spacing: 25px;
	}

	.app-logo {
		width: 1150px;
		height: 400px;
		//margin-top: 50px;
		position: relative;

		.logo-icon {
			top: 0px;
			left: 0px;
			position: absolute;

			.large-square {
				background: linear-gradient(270deg, #21D4FD 0%, #2152FF 100%);
				width: 360px;
				height: 200px;
				border-radius: 30px;
				position: absolute;
				top: 0px;
				left: 0px;
			}

			.small-square {
				background: #ffffff;
				width: 120px;
				height: 80px;
				border-radius: 30px 0px 0px 0px;
				position: absolute;
				top: 120px;
				left: 240px;
			}
		}

		.thumbz-text {
			font-size: 188px;
			font-weight: 900;
			position: absolute;
			top: -78px;
			left: 365px;
			width: 735px;
			height: 120px;
			color: #393939;
			letter-spacing: -5px;
		}

		.studio-text {
			font-size: 80px;
			font-weight: 900;
			position: absolute;
			top: 115px;
			left: 395px;
			width: 700px;
			height: 80px;
			color: #0080ff;
			letter-spacing: 85px;
		}
	}

	.loading-text {
		font-size: 40px;
		font-weight: 500;
		letter-spacing: 5px;
		margin-top: 50px;
	}
}

:root {
	--breakpoint-xs: 0;
	--breakpoint-sm: 800px;
	--breakpoint-md: 1000px;
	--breakpoint-lg: 1100px;
	--breakpoint-xl: 1200px;
}

.page {
	padding: 100px 50px 0px 50px !important;
}

.form-control:focus {
	box-shadow: none !important;
}

.mat-dialog-actions {
	margin-bottom: -24px !important;
	margin-top: -14px !important;

	.mat-button {
		border-radius: 10px !important;
		font-weight: 500 !important;
		height: 34px !important;
		line-height: 1 !important;
	}
}

.modal-header {
	font-size: 19px !important;
	font-weight: 600 !important;
	letter-spacing: 2px !important;
	border-bottom: none !important;
	padding: 1.5rem 1rem 0.5rem !important;
}

.modal-header .close {
	padding: 0px !important;
	margin-top: -10px !important;
}

.mat-dialog-container {
	padding: 15px !important;
	padding: 5px 24px 15px !important;
	border-radius: 20px !important;
}

.modal-body {
	padding: 10px 30px 15px !important;
	max-height: 60vh;
	overflow: scroll;
}

.modal-footer {
	padding: 10px 0px 10px 0px !important;
	border-top: none !important;
}

.tool-btn {
	margin: 3px !important;
	border-radius: 20px !important;
	font-size: 12px !important;
	white-space: normal !important;
	padding: 0px 5px !important;
	height: 30px !important;
	line-height: 30px !important;
	width: 100% !important;
}

.tool-section-overlay {
	border-radius: 20px;
	padding: 5px;
}

.header {
	margin: 0px -10px;
	height: 35px;
	line-height: 35px;
	font-size: 20px;
	padding: 0px 20px;
	margin-bottom: 0px;
}

.header-text {
	margin: 0px;
	font-size: 19px !important;
	font-weight: 600 !important;
	letter-spacing: 2px !important;
}

.tool-section {
	padding: 5px;
}

button:focus {
	outline: none !important;
}

[app-disabled] {
	opacity: 0.85;
	pointer-events: none;
}

.grp-wrapper, .grp-preview {
	height: 18px !important;
}

.selected-style {
	border-radius: 5px;
}

.mat-slider-horizontal .mat-slider-thumb-label-text {
	transform: rotate(-45deg) scale(0.7) !important;
}

button.mat-menu-item {
	font-size: 12px;
	height: 30px;
	line-height: 30px;
}

.mat-menu-panel {
	width: 180px
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-thumb {
	cursor: pointer !important;
}

.mandatory-field {
	width: 100%;
	display: inline-block;
}

.mandatory-asterisk {
	color: red;
	font-size: 15px;
	float: right;
	width: 0px;
	margin-right: -3px;
	padding-left: 3px;
}

.back-to-top {
	height: 50px !important;
	width: 50px !important;
	border-radius: 25px !important;

	mat-icon {
		margin-top: 5px;
		font-weight: 900;
	}
}

.grid-filter-div {
	position: absolute !important;
}

.progress-spinner {
	position: absolute !important;
}

.mat-slider-horizontal .mat-slider-ticks {
	background-image: none;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
	border: none !important;
}

::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
	cursor: pointer !important;
}

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
	stroke: #032085;
}

//Theme Colors

#main {
	background: #c3c3c352;
}

.mat-dialog-container {
	background: $light-modal-dialog-background-color !important;
	color: $light-modal-dialog-font-color !important;
}

.modal-header .close {
	color: $light-modal-dialog-close-button-color !important;
}

.back-to-top {
	background: $light-grid-back-to-top-button-background-color !important;
	color: $light-grid-back-to-top-button-font-color !important;
	border: 1px solid $light-grid-back-to-top-button-border-color !important;
	box-shadow: $light-grid-back-to-top-button-box-shadow-color !important;

	i, i:hover {
		background-color: transparent !important;
	}
}

.back-to-top:hover {
	background: $light-grid-back-to-top-button-background-color-hover !important;
}

#footer {
	background: $light-grid-footer-background-color !important;
	color: $light-grid-footer-font-color !important;

	h3 {
		color: $light-grid-footer-font-color !important;
	}

	.copyright {
		color: $light-grid-footer-copyright-font-color !important;
	}

	.social-links a {
		background-color: $light-grid-footer-social-links-background-color !important;
	}
}

.btn-ok {
	background: $light-modal-dialog-ok-button-background-color !important;
	color: $light-modal-dialog-ok-button-font-color !important;
	border: 1px solid $light-modal-dialog-ok-button-border-color !important;
	box-shadow: $light-modal-dialog-ok-button-box-shadow-color !important;
}

.btn-cancel {
	border: 2px solid $light-modal-dialog-cancel-button-border-color !important;
	box-shadow: $light-modal-dialog-cancel-button-box-shadow-color !important;
	background: $light-modal-dialog-cancel-button-font-color !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}

.btn-ok:hover {
	background: $light-modal-dialog-ok-button-background-color-hover !important;
}

.btn-cancel:hover {
	border: 2px solid $light-modal-dialog-cancel-button-border-color-hover !important;
	background: $light-modal-dialog-cancel-button-font-color-hover !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}

.modal-body {
	input, textarea, .dropdown-toggle {
		background: $light-modal-dialog-input-background-color !important;
		color: $light-modal-dialog-input-font-color !important;
		border: 1px solid $light-modal-dialog-input-border-color !important;
	}

	input:focus, textarea:focus, .dropdown-toggle:focus {
		background: $light-modal-dialog-input-background-color-focused !important;
		border: 1px solid $light-modal-dialog-input-border-color-focused !important;
	}

	input:disabled, textarea:disabled, .dropdown-toggle:disabled {
		background: $light-modal-dialog-input-background-color-disabled !important;
		color: $light-modal-dialog-input-font-color-disabled !important;
		border: 1px solid $light-modal-dialog-input-border-color-disabled !important;
	}
}

.cdk-overlay-dark-backdrop {
	background: #00000070;
}

.mat-slider-horizontal .mat-slider-track-fill {
	background: $light-tool-slider-left-background-color !important;
}

.mat-slider-horizontal .mat-slider-track-background, .mat-slider-horizontal .mat-slider-wrapper {
	background: $light-tool-slider-right-background-color !important;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
	background: $light-tool-slider-marker-color !important;
}

::-webkit-scrollbar-track {
	background: $light-scroll-bar-background-color !important;
}

::-webkit-scrollbar-corner {
	background: $light-scroll-bar-background-color !important;
}

::-webkit-scrollbar-thumb {
	background: $light-scroll-bar-handler-background-color !important;
}

::-webkit-scrollbar-thumb:hover {
	background: $light-scroll-bar-handler-background-color-hover !important;
}
