@font-face {
    font-family: Abril Fatface;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/AbrilFatface.ttf");
}

@font-face {
    font-family: Alfa Slab One;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/AlfaSlabOne.ttf");
}

@font-face {
    font-family: Amatic SC;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/AmaticSC.ttf");
}

@font-face {
    font-family: Anton;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Anton.ttf");
}

@font-face {
    font-family: Aradhana;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Aradhana.ttf");
}

@font-face {
    font-family: Arial;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Arial.ttf");
}

@font-face {
    font-family: Ballet;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Ballet.ttf");
}

@font-face {
    font-family: Bangers;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Bangers.ttf");
}

@font-face {
    font-family: BebasNeue;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/BebasNeue.ttf");
}

@font-face {
    font-family: Bungee Inline;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/BungeeInline.ttf");
}

@font-face {
    font-family: Bungee Shade;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/BungeeShade.ttf");
}

@font-face {
    font-family: Bungee Spice;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/BungeeSpice.ttf");
}

@font-face {
    font-family: Cabin;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Cabin.ttf");
}

@font-face {
    font-family: Cabin Italic;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Cabin-Italic.ttf");
}

@font-face {
    font-family: Calibr;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Calibr.ttf");
}

@font-face {
    font-family: Caveat;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Caveat.ttf");
}


@font-face {
    font-family: Comfortaa;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Comfortaa.ttf");
}

@font-face {
    font-family: Courgette;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Courgette.ttf");
}

@font-face {
    font-family: Fredoka One;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/FredokaOne.ttf");
}

@font-face {
    font-family: Indie Flower;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/IndieFlower.ttf");
}

@font-face {
    font-family: JosefinSans Italic;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/JosefinSans-Italic.ttf");
}

@font-face {
    font-family: Kanit ExtraBold;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Kanit-ExtraBold.ttf");
}


@font-face {
    font-family: Kanit Medium;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Kanit-Medium.ttf");
}

@font-face {
    font-family: Koulen;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Koulen.ttf");
}

@font-face {
    font-family: League Gothic;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/LeagueGothic.ttf");
}

@font-face {
    font-family: Lobster;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Lobster.ttf");
}

@font-face {
    font-family: LuckiestGuy;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/LuckiestGuy.ttf");
}

@font-face {
    font-family: Metal Mania;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/MetalMania.ttf");
}

@font-face {
    font-family: Mochiy PopPOne;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/MochiyPopPOne.ttf");
}

@font-face {
    font-family: Nabla;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Nabla.ttf");
}

@font-face {
    font-family: Noto Serif;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/NotoSerif.ttf");
}

@font-face {
    font-family: Open Sans;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/OpenSans.ttf");
}

@font-face {
    font-family: Oswald;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Oswald.ttf");
}

@font-face {
    font-family: Poppins Black;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Poppins-Black.ttf");
}

@font-face {
    font-family: Poppins Medium;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Poppins-Medium.ttf");
}

@font-face {
    font-family: Poppins SemiBold;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: Poppins Thin;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Poppins-Thin.ttf");
}

@font-face {
    font-family: Press Start;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/PressStart.ttf");
}

@font-face {
    font-family: Quicksand;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Quicksand.ttf");
}

@font-face {
    font-family: Qwigley;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Qwigley.ttf");
}

@font-face {
    font-family: Raleway;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Raleway.ttf");
}

@font-face {
    font-family: Raleway Italic;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Raleway-Italic.ttf");
}

@font-face {
    font-family: Righteous;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Righteous.ttf");
}

@font-face {
    font-family: Roboto;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Roboto.ttf");
}

@font-face {
    font-family: Roboto Black;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Roboto-Black.ttf");
}

@font-face {
    font-family: Roboto Light;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Roboto-Light.ttf");
}

@font-face {
    font-family: Roboto Slab;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/RobotoSlab.ttf");
}

@font-face {
    font-family: Rubik Iso;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/RubikIso.ttf");
}

@font-face {
    font-family: Russo One;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/RussoOne.ttf");
}

@font-face {
    font-family: Sail;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Sail.ttf");
}

@font-face {
    font-family: Satisfy;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Satisfy.ttf");
}

@font-face {
    font-family: Secular One;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/SecularOne.ttf");
}

@font-face {
    font-family: Shadows Into Light;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/ShadowsIntoLight.ttf");
}

@font-face {
    font-family: Sigmar One;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/SigmarOne.ttf");
}

@font-face {
    font-family: Suez One;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/SuezOne.ttf");
}

@font-face {
    font-family: Times New Roman;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/TimesNewRoman.ttf");
}

@font-face {
    font-family: Titillium Web Black;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/TitilliumWeb-Black.ttf");
}

@font-face {
    font-family: Titillium Web SemiBold;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/TitilliumWeb-SemiBold.ttf");
}

@font-face {
    font-family: Ubuntu;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/Ubuntu.ttf");
}

@font-face {
    font-family: Vast Shadow;
    src: url("https://prod-thumbaa-backend-fonts.s3.us-west-2.amazonaws.com/VastShadow.ttf");
}
