/**
* Template Name: Selecao - v2.3.0
* Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: Poppins, sans-serif;
    color: #444444;
}

a {
    color: #ef6603;
}

a:hover {
    color: #fc8129;
    text-decoration: none;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    display: none;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
}

.back-to-top i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: #ef6603;
    color: #fff;
    transition: all 0.4s;
}

.back-to-top i:hover {
    background: #fc7c1f;
    color: #fff;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    height: 70px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: rgba(42, 44, 57, 0.9);
}

#header-fixed {
    height: 70px;
    background: rgba(42, 44, 57, 0.9);
}

#header.header-transparent {
    background: transparent;
}

#header.header-scrolled {
    background: rgba(42, 44, 57, 0.9);
}

#header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
}

#header .logo h1 a, #header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
}

#header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu, .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    float: left;
}

.nav-menu a {
    display: block;
    position: relative;
    color: #fff;
    padding: 5px 15px 7px 15px;
    margin-left: 5px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 50px;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
    background: #ef6603;
    text-decoration: none;
}

.nav-menu .drop-down ul {
    display: block;
    position: absolute;
    left: 0;
    top: calc(100% - 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: ease all 0.3s;
    border-radius: 15px;
    margin-top: 5px;
}

.nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
}

.nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #2a2c39;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
    color: #ef6603;
    background: none;
}

.nav-menu .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
}

.nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
}

@media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
        left: -90%;
    }

    .nav-menu .drop-down .drop-down:hover > ul {
        left: -100%;
    }

    .nav-menu .drop-down .drop-down > a:after {
        content: "\ea9d";
    }
}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}

.mobile-nav-toggle i {
    color: #fff;
}

.mobile-nav {
    position: fixed;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    z-index: 9999;
    overflow-y: auto;
    background: #fff;
    transition: ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    padding: 10px 0;
}

.mobile-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mobile-nav a {
    display: block;
    position: relative;
    color: #2a2c39;
    padding: 10px 20px;
    font-weight: 500;
    outline: none;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
    color: #ef6603;
    text-decoration: none;
}

.mobile-nav .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 10px;
    position: absolute;
    right: 15px;
}

.mobile-nav .active.drop-down > a:after {
    content: "\eaa1";
}

.mobile-nav .drop-down > a {
    padding-right: 35px;
}

.mobile-nav .drop-down ul {
    display: none;
    overflow: hidden;
}

.mobile-nav .drop-down li {
    padding-left: 20px;
}

.mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(20, 21, 28, 0.6);
    overflow: hidden;
    display: none;
    transition: ease-in-out 0.2s;
}

.mobile-nav-active {
    overflow: hidden;
}

.mobile-nav-active .mobile-nav {
    opacity: 1;
    visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
}

/*--------------------------------------------------------------
# tool Section
--------------------------------------------------------------*/
#tool {
    width: 100%;
    overflow: hidden;
    position: relative;
    background: linear-gradient(0deg, #2a2c39 0%, #33364a 100%);
    padding: 0;
}

#tool .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    height: 70vh;
    padding-top: 60px;
}

#tool h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
}

#tool p {
    width: 80%;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: #fff;
}

#tool .carousel-control-prev, #tool .carousel-control-next {
    width: 10%;
}

#tool .carousel-control-next-icon, #tool .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
}

#tool .btn-get-started {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #ef6603;
}

#tool .btn-get-started:hover {
    background: #ef6603;
    color: #fff;
    text-decoration: none;
}

@media (min-width: 1024px) {
    #tool p {
        width: 60%;
    }

    #tool .carousel-control-prev, #tool .carousel-control-next {
        width: 5%;
    }
}

@media (max-width: 768px) {
    #tool .carousel-container {
        height: 90vh;
    }

    #tool h2 {
        font-size: 28px;
    }
}

.hero-waves {
    display: block;
    width: 100%;
    height: 60px;
    position: relative;
}

.wave1 use {
    -webkit-animation: move-forever1 10s linear infinite;
    animation: move-forever1 10s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave2 use {
    -webkit-animation: move-forever2 8s linear infinite;
    animation: move-forever2 8s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave3 use {
    -webkit-animation: move-forever3 6s linear infinite;
    animation: move-forever3 6s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }
    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }
    100% {
        transform: translate(-90px, 0%);
    }
}

@-webkit-keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

@-webkit-keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #fd9042;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2a2c39;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
    padding-top: 80px;
}

.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
}

.about .content ul li + li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #ef6603;
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .btn-learn-more {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #ef6603;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #ef6603;
}

.about .content .btn-learn-more:hover {
    background: #ef6603;
    color: #fff;
    text-decoration: none;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .nav-tabs {
    border: 0;
}

.features .nav-link {
    border: 1px solid #d4d6df;
    padding: 15px;
    transition: 0.3s;
    color: #2a2c39;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.features .nav-link i {
    padding-right: 15px;
    font-size: 48px;
}

.features .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.features .nav-link:hover {
    color: #ef6603;
}

.features .nav-link.active {
    background: #ef6603;
    color: #fff;
    border-color: #ef6603;
}

@media (max-width: 768px) {
    .features .nav-link i {
        padding: 0;
        line-height: 1;
        font-size: 36px;
    }
}

@media (max-width: 575px) {
    .features .nav-link {
        padding: 15px;
    }

    .features .nav-link i {
        font-size: 24px;
    }
}

.features .tab-content {
    margin-top: 30px;
}

.features .tab-pane h3 {
    font-weight: 600;
    font-size: 26px;
}

.features .tab-pane ul {
    list-style: none;
    padding: 0;
}

.features .tab-pane ul li {
    padding-bottom: 10px;
}

.features .tab-pane ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #ef6603;
}

.features .tab-pane p:last-child {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    background: #2a2c39;
    padding: 80px 0;
}

.cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.cta p {
    color: #fff;
}

.cta .cta-btn {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    color: #fff;
    background: #ef6603;
}

.cta .cta-btn:hover {
    background: #fff;
    color: #ef6603;
}

@media (max-width: 1024px) {
    .cta {
        background-attachment: scroll;
    }
}

@media (min-width: 769px) {
    .cta .cta-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.4s ease-in-out;
    width: 100%;
    height: 100%;
}

.services .icon-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 2px 35px 0 rgba(68, 88, 144, 0.2);
}

.services .icon {
    position: absolute;
    left: -20px;
    top: calc(50% - 30px);
}

.services .icon i {
    font-size: 64px;
    line-height: 1;
    transition: 0.5s;
}

.services .title {
    margin-left: 40px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

.services .title a {
    color: #2a2c39;
    transition: ease-in-out 0.3s;
}

.services .title a:hover {
    color: #ef6603;
}

.services .description {
    font-size: 14px;
    margin-left: 40px;
    line-height: 24px;
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# main-grid-section
--------------------------------------------------------------*/
.main-grid-section .template-item-main-filters {
    padding: 10px;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
}

.main-grid-section .template-item-main-filters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
    margin: 5px;
}

.main-grid-section .template-item-main-filters li:hover {
    color: #444444;
    background: #ffc296;
}

.main-grid-section .template-item-main-filters li.filter-active {
    color: #fff;
    background: #ef6603;
}

.main-grid-section .template-item-main-filters li:last-child {
    margin-right: 0;
}

.main-grid-section .template-grid-item {
    margin-bottom: 30px;
}

.main-grid-section .template-grid-item .template-item-default-image {
    overflow: hidden;
}

.main-grid-section .template-grid-item .template-item-default-image img {
    transition: all 0.8s ease-in-out;
}

.main-grid-section .template-grid-item .template-item-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 3;
    right: 15px;
    transition: all ease-in-out 0.5s;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
}

.main-grid-section .template-grid-item .template-item-info h4 {
    font-size: 13px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
}

.main-grid-section .template-grid-item .template-item-info p {
    color: #fedac0;
    font-size: 14px;
    margin-bottom: 0;
}

.main-grid-section .template-grid-item .template-item-info .preview-link, .main-grid-section .template-grid-item .template-item-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    transition: 0.3s;
}

.main-grid-section .template-grid-item .template-item-info .preview-link:hover, .main-grid-section .template-grid-item .template-item-info .details-link:hover {
    color: #fd9f5b;
}

.main-grid-section .template-grid-item .template-item-info .details-link {
    right: 10px;
}

.main-grid-section .template-grid-item .template-item-links {
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
}

.main-grid-section .template-grid-item .template-item-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.main-grid-section .template-grid-item .template-item-links a:hover {
    color: #fd9f5b;
}

.main-grid-section .template-grid-item:hover .template-item-default-image img {
    transform: scale(1.2);
}

.main-grid-section .template-grid-item:hover .template-item-info {
    opacity: 1;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 0 30px;
    margin: 30px 15px;
    text-align: center;
    min-height: 350px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 auto;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}

.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: #fedac0;
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
}

.testimonials .owl-nav, .testimonials .owl-dots {
    margin-top: 5px;
    text-align: center;
}

.testimonials .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
}

.testimonials .owl-dot.active {
    background-color: #ef6603 !important;
}

@media (max-width: 767px) {
    .testimonials {
        margin: 30px 10px;
    }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.pricing h3 {
    font-weight: 400;
    margin: -20px -20px 25px -20px;
    padding: 30px 15px;
    font-size: 18px;
    font-weight: 600;
    color: #777777;
    background: #f8f8f8;
}

.pricing h4 {
    font-size: 36px;
    color: #ef6603;
    font-weight: 600;
    margin-bottom: 20px;
}

.pricing h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
}

.pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
}

.pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}

.pricing ul li {
    padding-bottom: 16px;
}

.pricing ul i {
    color: #ef6603;
    font-size: 18px;
    padding-right: 4px;
}

.pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
}

.pricing .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
}

.pricing .btn-buy {
    background: #ef6603;
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border-radius: 50px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-weight: 600;
    transition: 0.3s;
}

.pricing .btn-buy:hover {
    background: #fc8129;
}

.pricing .featured {
    border: 2px solid #ef6603;
}

.pricing .featured h3 {
    color: #fff;
    background: #ef6603;
}

.right-side-lg {
    min-width: calc(100% * 30 / 100);
    max-width: calc(100% * 30 / 100);
}

.left-side-lg {
    min-width: calc(100% * 70 / 100);
    max-width: calc(100% * 70 / 100);
}

.pricing .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #ef6603;
    color: #fff;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq {
    padding: 60px 0;
}

.faq .faq-list {
    padding: 0;
    list-style: none;
}

.faq .faq-list li {
    padding: 0 0 20px 25px;
}

.faq .faq-list a {
    display: block;
    position: relative;
    font-size: 18px;
    font-weight: 500;
}

.faq .faq-list i {
    font-size: 18px;
    position: absolute;
    left: -25px;
    top: 6px;
}

.faq .faq-list p {
    margin-bottom: 20px;
    font-size: 15px;
}

.faq .faq-list a.collapse {
    color: #ef6603;
}

.faq .faq-list a.collapsed {
    color: #343a40;
}

.faq .faq-list a.collapsed:hover {
    color: #ef6603;
}

.faq .faq-list a.collapsed i::before {
    content: "\eab2" !important;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
    background: #fff;
    padding: 60px 0;
}

.team .member {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
    position: relative;
    overflow: hidden;
}

.team .member .social {
    position: absolute;
    left: 0;
    bottom: 30px;
    right: 0;
    opacity: 0;
    transition: ease-in-out 0.3s;
    text-align: center;
}

.team .member .social a {
    transition: color 0.3s;
    color: #2a2c39;
    margin: 0 3px;
    padding-top: 7px;
    border-radius: 50px;
    width: 36px;
    height: 36px;
    background: rgba(239, 102, 3, 0.8);
    display: inline-block;
    transition: ease-in-out 0.3s;
    color: #fff;
}

.team .member .social a:hover {
    background: #fc8129;
}

.team .member .social i {
    font-size: 18px;
}

.team .member .member-info {
    padding: 25px 15px;
}

.team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #2a2c39;
}

.team .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
}

.team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #777777;
}

.team .member:hover .social {
    opacity: 1;
    bottom: 15px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
    width: 100%;
    background: #fff;
}

.contact .info i {
    font-size: 20px;
    color: #ef6603;
    float: left;
    width: 44px;
    height: 44px;
    background: #ffecde;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2a2c39;
}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #555974;
}

.contact .info .email, .contact .info .phone {
    margin-top: 40px;
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #ef6603;
    color: #fff;
}

.contact .php-email-form {
    width: 100%;
    background: #fff;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
    background: #ef6603;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
    background: #fc8129;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    margin-top: 70px;
    background: #f7f8f9;
    min-height: 40px;
}

.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 400;
    margin: 0;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

@media (max-width: 992px) {
    .breadcrumbs ol {
        margin-top: 10px;
    }
}

.breadcrumbs ol li + li {
    padding-left: 10px;
}

.breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #404356;
    content: "/";
}

@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

/*--------------------------------------------------------------
# template-item Details
--------------------------------------------------------------*/
.template-item-details {
    padding-top: 40px;
}

.template-item-details .template-item-details-container {
    position: relative;
}

.template-item-details .template-item-details-carousel {
    position: relative;
    z-index: 1;
}

.template-item-details .template-item-details-carousel .owl-nav, .template-item-details .template-item-details-carousel .owl-dots {
    margin-top: 5px;
    text-align: left;
}

.template-item-details .template-item-details-carousel .owl-dot {
    display: inline-block;
    margin: 0 10px 0 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
}

.template-item-details .template-item-details-carousel .owl-dot.active {
    background-color: #ef6603 !important;
}

.template-item-details .template-item-info {
    padding: 30px;
    position: absolute;
    right: 0;
    bottom: -70px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.template-item-details .template-item-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.template-item-details .template-item-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.template-item-details .template-item-info ul li + li {
    margin-top: 10px;
}

.template-item-details .template-item-description {
    padding-top: 50px;
}

.template-item-details .template-item-description h2 {
    width: 50%;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.template-item-details .template-item-description p {
    padding: 0 0 0 0;
}

@media (max-width: 768px) {
    .template-item-details .template-item-info {
        position: static;
        margin-top: 30px;
    }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: #14151c;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 30px 0;
}

#footer h3 {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    position: relative;
    padding: 0;
    margin: 0 0 5px 0;
}

#footer p {
    font-size: 15px;
    font-style: italic;
    padding: 0;
    margin: 0 0 30px 0;
}

#footer .social-links {
    margin: 0 0 20px 0;
}

#footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #ef6603;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .social-links a:hover {
    background: #bd5102;
    color: #fff;
    text-decoration: none;
}

#footer .copyright {
    margin: 0 0 5px 0;
}

#footer .credits {
    font-size: 13px;
}
